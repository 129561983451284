// import React, { useState, useEffect } from "react";

// interface FAQItem {
//   id: number;
//   question: string;
//   answer: string;
// }

// interface FAQSectionProps {
//   faqItems: FAQItem[];
// }

// const FAQSection: React.FC<FAQSectionProps> = ({ faqItems }) => {
//   // Initialize state to have all FAQ items open
//   const [openItems, setOpenItems] = useState<Set<number>>(
//     new Set(faqItems.map((item) => item.id))
//   );

//   const toggle = (id: number) => {
//     setOpenItems((prev) => {
//       const newSet = new Set(prev);
//       if (newSet.has(id)) {
//         newSet.delete(id);
//       } else {
//         newSet.add(id);
//       }
//       return newSet;
//     });
//   };

//   return (
//     <div className="mx-auto p-6 flex flex-col items-center justify-center mt-8">
//       <div>
//         <h2 className="mb-7 text-center text-[var(--title-color)] text-2xl font-semibold tracking-tight md:text-4xl">
//           Frequently Asked <span className="gradient-new-text">Questions</span>
//         </h2>
//       </div>
//       {faqItems.map((item) => (
//         <div key={item.id} className="mb-2 max-w-[800px] w-full mx-auto">
//           <div
//             className="flex items-center justify-between bg-white rounded-lg pl-3 pr-2 py-3 text-[var(--title-color)] font-bold cursor-pointer"
//             onClick={() => toggle(item.id)}
//           >
//             <div className="flex-1">
//               <ul>
//                 <li>{item.question}</li>
//               </ul>
//             </div>
//             <span className="h-6 w-6 flex items-center justify-center text-teal-500 cursor-pointer">
//               <svg
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 fill="#0070f0"
//                 xmlns="http://www.w3.org/2000/svg"
//                 className={`transition-transform ${
//                   openItems.has(item.id) ? "rotate-180" : "rotate-0"
//                 }`}
//               >
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071Z"
//                   fill="#0070f0"
//                 />
//               </svg>
//             </span>
//           </div>
//           {openItems.has(item.id) && (
//             <div className="p-3 text-[var(--text-color)] -mt-4 rounded-b-lg bg-white">
//               <ul>
//                 <li>{item.answer}</li>
//               </ul>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default FAQSection;




import React, { useState } from "react";

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

interface FAQSectionProps {
  faqItems: FAQItem[];
}

const FAQSection: React.FC<FAQSectionProps> = ({ faqItems }) => {
  const [openItems, setOpenItems] = useState<Set<number>>(new Set());

  const toggle = (id: number) => {
    setOpenItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  return (
    <div className="mx-auto p-6 flex flex-col items-center justify-center mt-8">
    <div>
      <h2 className="mb-7 text-center text-[var(--title-color)] text-2xl font-semibold tracking-tight md:text-4xl">
        Frequently Asked <span className="gradient-new-text">Questions</span>
      </h2>
    </div>
    {faqItems.map((item) => (
      <div key={item.id} className="mb-2 max-w-[800px] w-full mx-auto">
        <div
          className="flex items-center justify-between bg-white rounded-lg pl-3 pr-2 py-3 text-[var(--title-color)] font-bold cursor-pointer"
        >
          {/* Added button element for better accessibility */}
          <button
            aria-expanded={openItems.has(item.id)}
            aria-controls={`faq-content-${item.id}`}
            onClick={() => toggle(item.id)}
            className="flex-1 text-left focus:outline-none"
          >
<h3>
{item.question}
</h3>
          </button>
          <span className="h-6 w-6 flex items-center justify-center text-teal-500 cursor-pointer">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#0070f0"
              xmlns="http://www.w3.org/2000/svg"
              className={`transition-transform ${
                openItems.has(item.id) ? "rotate-180" : "rotate-0"
              }`}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071Z"
                fill="#0070f0"
              />
            </svg>
          </span>
        </div>
  
        <div
          id={`faq-content-${item.id}`}
          className={`p-3 text-[var(--text-color)] -mt-4 rounded-b-lg bg-white ${
            openItems.has(item.id) ? "block" : "hidden"
          }`}
          role="region"
          aria-labelledby={`faq-content-${item.id}`}
        >
          {item.answer}
        </div>
      </div>
    ))}
  </div>
  
  );
};

export default FAQSection;
