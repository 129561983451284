import React from 'react'
import { useState } from 'react';

const SuccessStories = () => {

    const customerStories = [
        {
          id: "story1",
          title: "Peter Smith",
          testimonial:
            "The B2B data solutions of BizDataset have transformed our marketing efforts. We've seen an excellent improvement in lead quality and our customer acquisition. Highly recommended!",
          name: "Peter Smith",
        },
        {
          id: "story2",
          title: "Emily Davis",
          testimonial:
            "Since integrating BizDataset's data solutions, our sales cycle time has reduced. The accuracy of data reporting is phenomenal. My sales team is very happy. It's been a game-changer for our business.",
          name: "Emily Davis",
        },
        {
          id: "story3",
          title: "Michael Brown",
          testimonial:
            "BizDataset's B2B datasets have significantly boosted our conversion rates. The support team is exceptional, and the overall productivity of our sales team has skyrocketed. Truly impressive service!",
          name: "Michael Brown",
        },
        {
          id: "story4",
          title: "Jenny Mathews",
          testimonial:
            "We were initially skeptical, but the sample datasets provided helped us in making our decision. The dataset download process was smooth, and we were impressed with the quality of the data. A top-notch data provider!",
          name: "Jenny Mathews",
        },
        {
          id: "story5",
          title: "William Jones",
          testimonial:
            "Finding sales databases that are actually useful can be a challenge. Thankfully, this database provider offers an impressive range of high-quality sales datasets. Their support team was also very responsive and helpful.",
          name: "William Jones",
        },
        {
          id: "story6",
          title: "Mike Tyler",
          testimonial:
            "As a data-driven company, we rely heavily on our data vendors. BizDataset data provider stands out for their reliability and the ease of dataset download. We couldn’t be happier with the results!",
          name: "Mike Tyler",
        },
        {
          id: "story7",
          title: "Roselyn",
          testimonial:
            "We switched to the BizDataset database vendor after experiencing issues with our previous data provider. The difference is pronounced! The sample datasets helped us make an informed decision, and the quality of their datasets has been outstanding.",
          name: "Roselyn",
        },
        // Add more customer stories here
      ];

    const [isAnimationPaused, setIsAnimationPaused] = useState(false); // State to track animation pause

    const handleMouseEnter = () => {
      setIsAnimationPaused(true); // Pause animation for all cards
    };
  
    const handleMouseLeave = () => {
      setIsAnimationPaused(false); // Resume animation for all cards
    };


  return (
    <section className=" mb-8">
<div>
          <h2 className="text-center gradient-new-text tracking-wide text-2xl lg:text-4xl font-semibold  mb-10 mt-16 text-[var(--title-color)]">
            Our Success Stories
          </h2>
        </div>

    <div className="max-w-screen-2xl scrollbar-none mx-auto overflow-x-auto">
      <div>        
        <div
          className="box-border pl-3 pr-3 w-full mb-8 flex"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {customerStories.map((story) => (
            <div
              key={story.id}
              className="w-full md:w-[25%] mb-4 xl:mb-6 px-2 flex-shrink-0"
            >
              <div
                className={`p-6 md:p-4 xl:p-6 rounded-lg border-[#e1e5e8] border flex flex-col h-full bg-white shadow-lg overflow-hidden animate-marquee`}
                style={{
                  animationPlayState: isAnimationPaused ? 'paused' : 'running',
                  animationDelay: isAnimationPaused ? '0s' : `${-story.id * 2}s`, // Delay animation based on card index
                }}
              >
                <div className="flex mt-2">
                  {[...Array(5)].map((_, index) => (
                    <svg
                      key={index}
                      className="w-4 h-4 text-yellow-400"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                    </svg>
                  ))}
                </div>
                <div className="mt-3 mb-auto">
                  <div className="cursor-pointer box-border font-light tracking-wide leading-6 text-[var(--title-color)] text-base">
                    {story.testimonial}
                  </div>
                </div>
                <div className="border-t mt-3 pt-3">
                  <div className="flex items-center">
                    <div className="ml-2">
                      <div className="text-[var(--title-color)] font-semibold text-lg">
                        {story.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
  )
}

export default SuccessStories;