// import React, { useState, useEffect, useRef, useCallback } from 'react';

// type SuccessPopupProps = {
//   message: string;
//   onClose: () => void;
// };

// const SuccessPopup: React.FC<SuccessPopupProps> = ({ message, onClose }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [remainingTime, setRemainingTime] = useState(5000);
//   const timerRef = useRef<NodeJS.Timeout | null>(null);
//   const startTimer = useCallback((duration: number) => {
//     // Clear any existing timer
//     if (timerRef.current) {
//       clearTimeout(timerRef.current);
//     }

//     // Start a new timer
//     timerRef.current = setTimeout(() => {
//       onClose();
//     }, duration);
//   }, [onClose]);

//   useEffect(() => {
//     // Start the timer initially
//     startTimer(remainingTime);

//     // Clean up timer on component unmount
//     return () => {
//       if (timerRef.current) {
//         clearTimeout(timerRef.current);
//       }
//     };
//   }, [remainingTime, startTimer]);

//   useEffect(() => {
//     if (isHovered) {
//       // Pause the timer by clearing it
//       if (timerRef.current) {
//         clearTimeout(timerRef.current);
//         timerRef.current = null;
//       }
//     } else {
//       // Resume the timer from where it left off
//       startTimer(remainingTime);
//     }
//   }, [isHovered, remainingTime, startTimer]);

//   return (
//     <div
//       className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 bg-white  text-[#0070f0] px-4 py-2 rounded shadow-md flex items-center space-x-2"
//       onMouseEnter={() => {
//         setIsHovered(true);
//       }}
//       onMouseLeave={() => {
//         setIsHovered(false);
//       }}
//     >
//       <p className="flex-1 ">{message}</p>
//       <button
//         onClick={onClose}
//         className="w-6 h-6 flex items-center justify-center"
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 24 24"
//           className="w-4 h-4 text-[#0070f0]"
//           fill="#0070f0"
//           stroke="currentColor"
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         >
//           <path d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//     </div>
//   );
// };

// export default SuccessPopup;



import React, { useState, useEffect, useRef, useCallback } from 'react';

type SuccessPopupProps = {
  message: string;
  onClose: () => void;
  duration?: number; // Optional prop for duration
};

const SuccessPopup: React.FC<SuccessPopupProps> = ({ message, onClose, duration = 5000 }) => {
  const [isHovered, setIsHovered] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Start a new timer
    timerRef.current = setTimeout(() => {
      onClose();
    }, duration);
  }, [onClose, duration]);

  useEffect(() => {
    // Start the timer initially
    startTimer();

    // Clean up timer on component unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [startTimer]);

  useEffect(() => {
    if (isHovered) {
      // Pause the timer by clearing it
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    } else {
      // Resume the timer
      startTimer();
    }
  }, [isHovered, startTimer]);

  return (
    <div
      className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 bg-white text-[#0070f0] px-4 py-2 rounded shadow-md flex items-center space-x-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-live="polite" // Accessibility enhancement
    >
      <p className="flex-1">{message}</p>
      <button
        onClick={onClose}
        className="w-6 h-6 flex items-center justify-center hover:bg-[#e0e0e0] rounded transition duration-200" // Improved button style
        aria-label="Close" // Accessibility improvement
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-4 h-4 text-[#0070f0]"
          fill="#0070f0"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};

export default SuccessPopup;
